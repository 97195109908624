import React from "react";
import { useStaticQuery, graphql, Link } from "gatsby";
import Img from "gatsby-image";
import Layout from "../components/layout/layout";
import Metadata from "../components/metadata/metadata";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Info from "../components/info/info";
import { AiOutlineBank } from "@react-icons/all-files/ai/AiOutlineBank";
import { HiOutlineScale } from "@react-icons/all-files/hi/HiOutlineScale";
// import { IoFingerPrint } from "@react-icons/all-files/io5/IoFingerPrint"
import { AiOutlineNodeIndex } from "@react-icons/all-files/ai/AiOutlineNodeIndex";

const primaryColor = "#f44f18";

const Governance = () => {
  const data = useStaticQuery(
    graphql`
      query {
        file(
          relativePath: { eq: "images/stripes/White_Mountain_Range_Top.jpg" }
        ) {
          childImageSharp {
            fluid(maxWidth: 960, maxHeight: 150) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        allMarkdownRemark(
          filter: { frontmatter: { type: { eq: "governance" } } }
          sort: { fields: frontmatter___order, order: ASC }
        ) {
          edges {
            node {
              frontmatter {
                title
                date(formatString: "DD MMMM, YYYY")
                abstract
                featured {
                  childImageSharp {
                    fluid(maxWidth: 750) {
                      ...GatsbyImageSharpFluid
                    }
                  }
                }
              }
              timeToRead
              excerpt
              id
              fields {
                slug
              }
            }
          }
        }
      }
    `
  );

  return (
    <Layout>
      <Metadata
        title="Governance"
        description="Hope Labs believes in integrity and transparency. Our corporate principles reflect our commitment to have a positive impact."
      />
      <section role="main">
        <Row className={"no-margin"}>
          <Img
            fluid={data.file.childImageSharp.fluid}
            alt="White Mountain range"
          />
        </Row>
        <Row className={"no-margin presentation"}>
          <Col>
            <h1>{"Governance"}</h1>
            <p>
              {
                "Hope Labs’ mission is to support all ventures that have a net positive impact on society by sharing their stories to inspire, leave a mark, and change the world. As we are social enterprise, we are committed to upholding this mission and our values in everything we do. To that end, our Board of Directors has created corporate guidelines to provide structure to how we operate and conduct business."
              }
            </p>
          </Col>
        </Row>
        <Row
          className={"no-margin presentation"}
          style={{ justifyContent: "space-evenly" }}
        >
          <Col xs={12} sm={12} md={6} lg={5} xl={5}>
            <Info
              icon={AiOutlineBank}
              iconColor={primaryColor}
              iconSize={"100px"}
              title={data.allMarkdownRemark.edges[0].node.frontmatter.title}
              description={
                data.allMarkdownRemark.edges[0].node.frontmatter.abstract
              }
              fixedHeight="9rem"
              justifyText={true}
              link={""}
            >
              <div role="button" className={"moreButton"}>
                <Link
                  to={`/governance/${data.allMarkdownRemark.edges[0].node.fields.slug}/`}
                >
                  Read More
                </Link>
              </div>
            </Info>
          </Col>
          <Col xs={12} sm={12} md={6} lg={5} xl={5}>
            <Info
              icon={AiOutlineNodeIndex}
              iconColor={primaryColor}
              iconSize={"100px"}
              title={data.allMarkdownRemark.edges[1].node.frontmatter.title}
              description={
                data.allMarkdownRemark.edges[1].node.frontmatter.abstract
              }
              fixedHeight="9rem"
              justifyText={true}
              link={""}
            >
              <div role="button" className={"moreButton"}>
                <Link
                  to={`/governance/${data.allMarkdownRemark.edges[1].node.fields.slug}/`}
                >
                  Read More
                </Link>
              </div>
            </Info>
          </Col>
        </Row>
        <Row
          className={"no-margin presentation"}
          style={{ justifyContent: "space-evenly", marginBottom: "2em" }}
        >
          <Col xs={12} sm={12} md={6} lg={5} xl={5}>
            <Info
              icon={HiOutlineScale}
              iconColor={primaryColor}
              iconSize={"100px"}
              title={data.allMarkdownRemark.edges[2].node.frontmatter.title}
              description={
                data.allMarkdownRemark.edges[2].node.frontmatter.abstract
              }
              fixedHeight="9rem"
              justifyText={true}
              link={""}
            >
              <div role="button" className={"moreButton"}>
                <Link
                  to={`/governance/${data.allMarkdownRemark.edges[2].node.fields.slug}/`}
                >
                  Read More
                </Link>
              </div>
            </Info>
          </Col>
          {/* <Col xs={12} sm={12} md={5} lg={5} xl={5}>
                        <Info
                            icon={IoFingerPrint}
                            iconColor={primaryColor}
                            iconSize={"100px"}
                            title={data.allMarkdownRemark.edges[3].node.frontmatter.title}
                            description={data.allMarkdownRemark.edges[3].node.frontmatter.abstract}
                            fixedHeight= "10rem"
                            justifyText={true}
                            link={""}
                        >
                            <div role="button" className={"moreButton"}>
                                <Link to={`/governance/${data.allMarkdownRemark.edges[3].node.fields.slug}/`}>Read More</Link>
                            </div>
                        </Info>
                    </Col> */}
        </Row>
      </section>
    </Layout>
  );
};

export default Governance;
